import ErrorPage from 'components/error-page'

export default function Page() {
  return (
    <div className="flex min-h-screen justify-center py-40">
      <ErrorPage title="404 Page not found">
        <p className="text-gray-700">The page you were looking for does not exist.</p>
        <a href="/" className="mt-4 inline-block border-b text-gray-700">
          Return to main page
        </a>
      </ErrorPage>
    </div>
  )
}
