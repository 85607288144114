import brand from 'brand'
import AdminIndicator from 'components/admin-indicator'
import useAdmin from 'hooks/use-admin'
import { createContext, useContext, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import useToken from './hooks/use-token'
import Home from './pages/index'
import NotFound from './pages/not-found'
import Request from './pages/request'
import Summary from './pages/summary'

export const AppContext = createContext({
  token: null,
  setToken: () => {},
  removeToken: () => {},
  isAdminInCharge: false,
  setAdminInCharge: () => {},
})

function RequireToken({ children }) {
  const { token } = useContext(AppContext)

  if (!token) {
    return <Navigate to="/" replace />
  }

  return children ? children : <Outlet />
}

function App() {
  const token = useToken()
  const admin = useAdmin()

  useEffect(() => {
    document.body.classList.add('theme-' + brand.theme)

    return () => document.body.classList.remove('theme-' + brand.theme)
  }, [])

  return (
    <AppContext.Provider value={{ ...token, ...admin }}>
      <AdminIndicator />
      <Routes>
        <Route index element={<Home />} />
        <Route element={<RequireToken />}>
          <Route path="/request" element={<Request />} />
          <Route path="/summary" element={<Summary />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContext.Provider>
  )
}

export default App
