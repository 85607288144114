import { CgMathMinus, CgMathPlus } from 'react-icons/cg'
import { classNames } from 'utils'

export default function QuantitySelector({ value, min = 0, max, onChange, className }) {
  return (
    <div className={classNames('flex overflow-hidden border border-gray-300 text-center sm:text-sm', className)}>
      <button
        className="flex w-8 items-center justify-center text-gray-500 transition enabled:hover:bg-black enabled:hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
        disabled={value <= min}
        onClick={() => onChange(value > min ? value - 1 : min)}
        type="button"
      >
        <CgMathMinus className="h-4 w-4" />
      </button>
      <span className="flex flex-1 items-center justify-center px-4 py-2">{value}</span>
      <button
        className="flex w-8 items-center justify-center text-gray-500 transition enabled:hover:bg-black enabled:hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
        disabled={value >= max}
        onClick={() => onChange(value < max ? value + 1 : max)}
        type="button"
      >
        <CgMathPlus className="h-4 w-4" />
      </button>
    </div>
  )
}
