import { AppContext } from 'App'
import Alert from 'components/alert'
import AppButton from 'components/app-button'
import AppInput from 'components/app-input'
import AppLogo from 'components/app-logo'
import { LoadingIcon } from 'components/icons'
import InputError from 'components/input-error'
import InputLabel from 'components/input-label'
import TwoColumnLayout from 'components/two-column-layout'
import useForm from 'hooks/use-form'
import { __ } from 'lang'
import { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ReturnsService from 'services/returns-service'
import { useMount } from 'utils'
import * as yup from 'yup'

export default function Page() {
  const navigate = useNavigate()
  const app = useContext(AppContext)
  const [searchParams] = useSearchParams()

  const form = useForm({
    request: ReturnsService.searchOrder,

    defaults: {
      email: searchParams.get('email') || '',
      orderNo: searchParams.get('order_no') || '',
      isAdminInCharge: false,
    },

    rules: {
      email: yup.string().email().required(),
      orderNo: yup.string().required(),
    },

    onSuccess: (res) => {
      app.setToken(res.data.data)
      navigate('/request')
    },
  })

  useMount(() => {
    if (searchParams.has('admin')) {
      form.setData('isAdminInCharge', searchParams.get('admin') === '1')
      app.setAdminInCharge(searchParams.get('admin') === '1')
    }

    if (searchParams.get('status') === 'ERR_SESSION_TIMEOUT') {
      form.setErrorCode('err_session_timeout')
      navigate('/', { replace: true })
    }
  })

  return (
    <TwoColumnLayout>
      <div className="mx-auto w-full max-w-md px-4 py-6 md:py-12 md:px-6">
        <div className="text-center sm:text-left">
          <a
            href={process.env.REACT_APP_HOME_PAGE_URL}
            className="inline-flex"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AppLogo className="h-10 w-auto" />
          </a>
        </div>

        <div className="mt-8 text-center uppercase tracking-widest text-primary-800 sm:text-left">
          {__('Returns Portal')}
        </div>

        <p className="mt-8 text-sm leading-relaxed text-gray-700">{__('page_login_message')}</p>

        {form.error && (
          <Alert
            className="mt-8"
            description={__(form.error.data?.code?.toLowerCase() || 'alert_error_default_description')}
            title={__('alert_error_default_title')}
            variant="danger"
          />
        )}

        <form className="mt-8" onSubmit={form.submit}>
          <div className="space-y-2">
            <InputLabel htmlFor="email">{__('Email address')}</InputLabel>
            <AppInput
              hasError={form.hasError('email')}
              id="email"
              onBlur={() => form.setData('email', form.data.email.trim())}
              onChange={(e) => form.setData('email', e.target.value)}
              value={form.data.email}
            />
            <InputError message={form.errors?.email} />
          </div>

          <div className="mt-4 space-y-2">
            <InputLabel htmlFor="order-number">{__('Order number')}</InputLabel>
            <AppInput
              hasError={form.hasError('orderNo')}
              id="order-number"
              onBlur={() => form.setData('orderNo', form.data.orderNo.trim())}
              onChange={(e) => form.setData('orderNo', e.target.value)}
              value={form.data.orderNo}
            />
            <InputError message={form.errors?.orderNo} />
            <div className="pl-3 pt-1 text-[11px] font-normal text-[#424242]">eg. HMUS-1045</div>
          </div>

          <AppButton
            type="submit"
            color="primary"
            className="relative mt-10 w-full px-6 py-3 text-xs uppercase tracking-widest"
            disabled={form.processing}
          >
            {form.processing ? <span>{__('Searching order...')}</span> : <span>{__('Search order')}</span>}
            {form.processing && <LoadingIcon className="ml-2 h-4 w-4 animate-spin text-white" />}
          </AppButton>
        </form>
      </div>
    </TwoColumnLayout>
  )
}
