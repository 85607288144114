import { __ } from 'lang'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiOutlineLoading } from 'react-icons/ai'
import { FiX } from 'react-icons/fi'
import Resizer from 'react-image-file-resizer'
import { classNames, useMount } from 'utils'

let id = 0

const resizeFile = (file, { maxWidth, maxHeight }) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

const DropzoneFile = (file) =>
  Object.assign(file, {
    _id: ++id,
    preview: URL.createObjectURL(file),
  })

export default function Dropzone({
  accept = { 'image/*': [] },
  limit = 5,
  maxSize = 5242880, // megabytes
  maxWidth = 720, // bytes, used to resize image before uploading
  maxHeight = 720, // bytes, used to resize image before uploading
  onChange,
  value,
}) {
  const [processing, setProcessing] = useState(false)

  const dropzone = useDropzone({
    accept,
    maxSize,
    maxFiles: limit,
    noClick: true,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        window.alert(__('err_max_file_size'))
      }

      const newFiles = [...value]

      setProcessing(true)

      for (const file of acceptedFiles) {
        if (newFiles.length >= limit) {
          break
        }

        const optimizedFile = await resizeFile(file, { maxWidth, maxHeight })
        newFiles.push(DropzoneFile(optimizedFile))
      }

      setProcessing(false)

      onChange(newFiles)
    },
  })

  useMount(() => () => {
    value.forEach((file) => file.preview && URL.revokeObjectURL(file.preview))
  })

  return (
    <div
      {...dropzone.getRootProps({
        className: classNames(
          'dropzone flex items-center justify-center relative border p-3 min-h-[178px]',
          value.length === 0 && 'border-dashed'
        ),
      })}
    >
      <input {...dropzone.getInputProps()} />

      {value.length > 0 ? (
        <div className="grid grid-cols-3 gap-4 lg:grid-cols-5">
          {value.map((file) => (
            <FilePreview key={file._id} file={file} value={value} onChange={onChange} />
          ))}

          {value.length < limit && <AddMoreButton onClick={() => dropzone.open()} />}
        </div>
      ) : (
        <div className="text-center">
          <ImagesIcon className="inline-block h-12 w-12 text-gray-300" />
          <p className="mt-4 text-sm text-gray-700">
            <button
              className="font-medium text-primary-700 hover:text-primary-900"
              onClick={() => dropzone.open()}
              type="button"
            >
              Upload a file
            </button>{' '}
            or drag and drop.
          </p>
          <p className="mt-1 text-sm text-gray-400">PNG, GIF or JPG, up to 5MB</p>
        </div>
      )}

      {processing && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100/50">
          <AiOutlineLoading className="h-8 w-8 animate-spin text-primary-700" />
        </div>
      )}
    </div>
  )
}

function FilePreview({ file, value, onChange }) {
  return (
    <div className="group relative aspect-square overflow-hidden border">
      <img alt={file.name} className="h-full w-full object-scale-down group-hover:blur" src={file.preview} />

      <button
        className="absolute top-1 right-1 inline-flex h-6 w-6 items-center justify-center text-gray-500"
        onClick={() => onChange(value.filter((f) => f._id !== file._id))}
        aria-label={`remove ${file.name}`}
        type="button"
      >
        <FiX className="h-5 w-5" />
      </button>
    </div>
  )
}

function AddMoreButton(props) {
  return (
    <button
      {...props}
      className="inline-flex aspect-square items-center justify-center border border-dashed border-gray-300 hover:border-gray-400"
      type="button"
    >
      <span className="bg-primary-400 px-2 py-1 text-xs uppercase tracking-wider text-primary-700">Add more</span>
    </button>
  )
}

function ImagesIcon({ className }) {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="none"
      strokeWidth="1"
      viewBox="0 0 24 24"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
      ></path>
    </svg>
  )
}
