import { __ } from 'lang'
import { classNames } from 'utils'

export default function ErrorPage({ title = __('page_error_default_title'), className, children }) {
  return (
    <div className={classNames('py-20 text-center', className)}>
      {title && <h1 className="text-3xl uppercase tracking-wide">{title}</h1>}
      <div className="mx-auto mt-2 w-full max-w-md">{children}</div>
    </div>
  )
}
