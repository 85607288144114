import { createElement, forwardRef } from 'react'
import { classNames } from 'utils'

const AppButton = forwardRef(
  ({ as = 'button', children, className, color = 'default', type = 'button', ...props }, ref) => {
    return createElement(
      as,
      {
        ...props,
        type,
        ref,
        className: classNames(
          `inline-flex items-center justify-center transition focus:outline-none disabled:opacity-50`,
          color === 'default' && 'border bg-white text-gray-700 hover:bg-gray-200 focus:border-gray-300',
          color === 'primary' && 'border border-black bg-black text-white hover:bg-gray-800',
          color === 'yellow' && 'border border-[#FFDE00] bg-[#FFDE00] text-black',
          className
        ),
      },
      children
    )
  }
)

AppButton.displayName = 'AppButton'

export default AppButton
