import { classNames } from 'utils'

export default function AppSelect({ className, children, hasError = false, ...props }) {
  return (
    <select
      className={classNames(
        'form-select block w-full border-gray-300 px-3 py-2 focus:border-black focus:outline-none focus:ring-0 sm:text-sm',
        hasError && '!border-red-500',
        className
      )}
      {...props}
    >
      {children}
    </select>
  )
}
