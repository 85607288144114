import { __ } from 'lang'
import { Link } from 'react-router-dom'
import AppLogo from './app-logo'
import Breadcrumbs from './breadcrumbs'

export default function WithHeaderLayout({ children }) {
  return (
    <>
      <header className="mx-auto flex w-full max-w-5xl flex-col items-center justify-between py-4 px-6 sm:h-16 sm:flex-row sm:py-0">
        <AppLogo className="mb-6 h-8 w-auto sm:mb-1" />
        <div className="flex items-center space-x-4">
          <Link to="/" className="text-xs uppercase tracking-wider text-gray-700 hover:text-primary-700">
            {__('Search order')}
          </Link>
          <a
            href={process.env.REACT_APP_RETURNS_POLICY_PAGE_URL}
            target="_blank"
            rel="noreferrer noopener"
            className="text-xs uppercase tracking-wider text-gray-700 hover:text-primary-700"
          >
            {__('Returns policy')}
          </a>
          <a
            className="text-xs uppercase tracking-wider text-gray-700 hover:text-primary-700"
            href={process.env.REACT_APP_CONTACT_PAGE_URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            {__('Contact')}
          </a>
          <a
            className="text-xs uppercase tracking-wider text-gray-700 hover:text-primary-700"
            href={process.env.REACT_APP_HOME_PAGE_URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            {__('Shop')}
          </a>
        </div>
      </header>
      <Breadcrumbs className="mt-4">
        <Breadcrumbs.Item>
          <a
            className="text-primary-700 hover:underline"
            href={process.env.REACT_APP_HOME_PAGE_URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            {process.env.REACT_APP_HOME_PAGE_LABEL}
          </a>
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>{__('Returns Portal')}</Breadcrumbs.Item>
      </Breadcrumbs>
      <main className="mx-auto w-full max-w-5xl px-6 py-8">{children}</main>
    </>
  )
}
