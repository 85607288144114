import http from 'http'

const PaymentsService = {
  createCheckout: (params) =>
    new Promise((resolve) => {
      const url = process.env.REACT_APP_RETURNS_PAYMENT_URL
      const query = new URLSearchParams(params).toString()
      resolve({ data: { url: `${url}?${query}` } })
    }),

  getPayment: (params) => http.get('/returns/getPayment', { params }),
}

export default PaymentsService
