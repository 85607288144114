import { classNames } from 'utils'

export default function AppRadio({ className, children, ...props }) {
  return (
    <label className={classNames('inline-flex items-center', className)}>
      <input
        className="form-radio h-4 w-4 border-primary-700 text-primary-700 shadow-sm focus:ring-0 disabled:border-primary-300"
        type="radio"
        {...props}
      />
      <span className={classNames('text-sm', props.disabled && 'text-primary-300')}>{children}</span>
    </label>
  )
}
