import { AppContext } from 'App'
import Alert from 'components/alert'
import AppButton from 'components/app-button'
import AppInput from 'components/app-input'
import AppRadio from 'components/app-radio'
import ConfirmModal from 'components/confirm-modal'
import ErrorPage from 'components/error-page'
import { LoadingIcon } from 'components/icons'
import InputError from 'components/input-error'
import InputLabel from 'components/input-label'
import InputLineItem from 'components/input-line-item'
import WithHeaderLayout from 'components/with-header-layout'
import useFlash from 'hooks/use-flash'
import useForm from 'hooks/use-form'
import { __ } from 'lang'
import { groupBy } from 'lodash'
import { times } from 'lodash'
import { createContext, useContext, useMemo, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import ReturnsService from 'services/returns-service'
import { pluralize, useMount } from 'utils'
import * as yup from 'yup'

const PageContext = createContext({
  form: null,
  itemsToReturn: [],
  uploading: false,
  setUploading: () => {},
  orders: [],
  addOrder: () => {},
  reasons: [],
  showConfirmModal: false,
  closeConfirmModal: () => {},
  showLinkModal: false,
  toggleLinkModal: () => {},
})

export const LineItemModel = (attributes) => ({
  ...attributes,
  can_return: attributes.is_returnable && attributes.qty_ordered - attributes.qty_refunded > 0,
  files: [],
  other_reason: '',
  qty_to_return: 1,
  reason: '',
  description: '',
  refundable_qty: attributes.qty_ordered - attributes.qty_refunded,
  should_return: false,
})

const ItemToReturnModel = (attributes) => ({
  middleware_item_id: attributes.middleware_item_id,
  qty_to_return: attributes.qty_to_return,
  description: attributes.description,
  reason:
    String(attributes.reason).toUpperCase() === 'OTHER' //
      ? attributes.other_reason
      : attributes.reason,
  sales_order_id: attributes.sales_order_id,
  sku: attributes.sku,
})

export const matchReason = (haystack, needle) =>
  haystack
    .map((str) => str.toUpperCase()) //
    .includes(String(needle).toUpperCase())

export default function Page() {
  const [error, setError] = useState(null)
  const [orders, setOrders] = useState([])
  const [reasons, setReasons] = useState([])
  const [uploading, setUploading] = useState(false)
  const [showConfirmModal, toggleConfirmModal] = useState(false)
  const [showLinkModal, toggleLinkModal] = useState(false)

  const app = useContext(AppContext)
  const flash = useFlash()
  const navigate = useNavigate()

  const form = useForm({
    request: ReturnsService.createReturns,

    defaults: {
      confirming: false,
      country: '',
      line_items: [],
      phone: '',
      refund_method: 'store_credits',
      sales_order_id: null,
      store_id: null,
      token: app.token,
      isAdminInCharge: app.isAdminInCharge,
    },

    rules: {
      confirming: yup.boolean(),

      phone: yup
        .string()
        .nullable()
        .when(['confirming', 'country'], {
          is: (confirming) => confirming,
          then: () =>
            yup
              .string()
              .required()
              .min(5, 'Phone must be between 5-30 characters')
              .max(30, 'Phone must be between 5-30 characters'),
        }),

      refund_method: yup
        .string()
        .nullable()
        .when('confirming', {
          is: true,
          then: () => yup.string().required().oneOf(['store_credits', 'refund']),
        }),

      line_items: yup.array().of(
        yup.object({
          should_return: yup.boolean(),

          reason: yup.string().when('should_return', {
            is: true,
            then: () => yup.string().required(),
          }),

          other_reason: yup.string().when('reason', {
            is: (reason) => matchReason(['OTHER'], reason),
            then: () => yup.string().required(),
          }),

          description: yup.string().when('reason', {
            is: (reason) => matchReason(['FAULT', 'FAULTY'], reason),
            then: () => yup.string().required(),
          }),

          files: yup.array().when('reason', {
            is: (reason) => matchReason(['FAULT', 'FAULTY', 'WRONG ITEM RECEIVED'], reason),
            then: () =>
              yup
                .array() //
                .min(1, 'Must upload at least one photo')
                .max(5, 'Must upload not more than five photos'),
          }),
        }),
      ),
    },

    transform: (originalData) => ({
      isAdminInCharge: originalData.isAdminInCharge,
      data: Object.values(groupBy(itemsToReturn.map(ItemToReturnModel), 'sales_order_id')).map((lineItems) => ({
        country: originalData.country,
        is_use_refund: originalData.refund_method === 'refund',
        is_use_store_credit: originalData.refund_method === 'store_credits',
        line_items: lineItems,
        phone: originalData.phone,
        sales_order_id: lineItems[0].sales_order_id,
        store_id: originalData.store_id,
        token: orders.find((o) => o.sales_order_id === lineItems[0].sales_order_id)?.token,
      })),
    }),

    onBeforeSend: () => {
      if (showConfirmModal) {
        setUploading(true)
      } else {
        toggleConfirmModal(true)
        form.setData('confirming', true)
      }

      return showConfirmModal
    },

    onSuccess: async (res) => {
      const hash = res.data.data.return_hash

      await ReturnsService.reloadOrder({
        isAdminInCharge: true,
        hash: app.token,
      }).then((res) => {
        app.setToken(res.data.data)
      })

      const toUpload = itemsToReturn
        .filter(({ reason }) => matchReason(['FAULT', 'FAULTY', 'WRONG ITEM RECEIVED'], reason))
        .filter(({ files }) => files.length > 0)

      for (const item of toUpload) {
        const formData = new FormData()
        formData.append('return_hash', hash)
        formData.append('middleware_item_id', item.middleware_item_id)
        item.files.forEach((file, i) => formData.append(`files[${i}]`, file))
        await ReturnsService.addImagesToItem(formData)
      }

      flash.remember({
        title: __('returns_created_title', { hash }),
        description: __('returns_created_body'),
      })

      navigate('/summary')
    },

    onError: (err) => {
      if (err.response?.data?.code === 'ERR_SESSION_TIMEOUT') {
        navigate('/?status=ERR_SESSION_TIMEOUT')
      } else {
        closeConfirmModal()
      }
    },

    onComplete: () => {
      setUploading(false)
    },
  })

  const itemsToReturn = useMemo(() => {
    return form.data.line_items.filter((item) => item.qty_to_return > 0 && item.should_return)
  }, [form.data.line_items])

  useMount(() => {
    ReturnsService.getReasons().then((res) => setReasons(res.data.data))

    ReturnsService.getOrder({ hash: app.token })
      .then((res) => addOrder(res.data.data))
      .catch((err) => {
        if (err.code === 'err_order_unavailable_for_return') {
          navigate('/summary', { replace: true })
        } else {
          setError(err.response)
        }
      })
  })

  function closeConfirmModal() {
    toggleConfirmModal(false)
    form.setData('refund_method', 'store_credits')
    form.setData('confirming', false)
  }

  function addOrder(order) {
    if (!order.isCreateEnabled) {
      let err = new Error(__('err_order_unavailable_for_return'))
      err.code = 'err_order_unavailable_for_return'
      throw err
    }

    const newOrders = [...orders]

    if (newOrders.length > 0 && newOrders[0].store_id !== order.store_id) {
      let err = new Error(__('err_order_from_different_store'))
      err.code = 'err_order_from_different_store'
      throw err
    }

    if (newOrders.map((o) => o.sales_order_id).includes(order.sales_order_id)) {
      return
    }

    newOrders.push(order)

    setOrders(newOrders)

    form.setData({
      store_id: order.store_id,
      sales_order_id: order.sales_order_id,
      line_items: [...form.data.line_items, ...Object.values(order.line_items).map(LineItemModel)],
      phone: order.phone || '',
      country: order.data.sales_order_addresses //
        .find((address) => address.is_shipping)
        ?.country_code?.toLowerCase(),
    })
  }

  return (
    <PageContext.Provider
      value={{
        form,
        itemsToReturn,
        uploading,
        setUploading,
        orders,
        addOrder,
        reasons,
        showConfirmModal,
        closeConfirmModal,
        showLinkModal,
        toggleLinkModal,
      }}
    >
      <WithHeaderLayout>
        {orders.length >= 1 && error === null && <PageLoaded />}
        {orders.length === 0 && error === null && <PageLoading />}
        {error !== null && <PageError />}
      </WithHeaderLayout>
    </PageContext.Provider>
  )
}

function PageLoaded() {
  const navigate = useNavigate()
  const page = useContext(PageContext)

  return (
    <div>
      <div className="flex items-start justify-between">
        <div>
          <h1 className="uppercase tracking-wider">
            {page.orders.map((order) => __('Order') + ' ' + order.order_no).join(' + ')}
          </h1>

          <p className="mt-2 text-sm text-gray-500">{__('Please select the items to return.')}</p>
        </div>

        <AppButton
          type="button"
          onClick={() => page.toggleLinkModal(true)}
          className="px-4 py-2 text-xs uppercase tracking-widest"
        >
          <FiPlus className="mr-2 h-4 w-4" />
          <span>Link orders</span>
        </AppButton>
      </div>

      {page.form.error && (
        <Alert
          className="mt-8"
          description={__(page.form.error.data?.code || 'alert_error_default_description')}
          title={__('alert_error_default_title')}
          variant="danger"
          tabIndex="-1"
        />
      )}

      <form onSubmit={page.form.submit} className="mt-10">
        <ul className="space-y-16">
          {page.form.data.line_items.map((item, i) => (
            <InputLineItem
              errors={{
                reason: page.form.errors[`line_items[${i}].reason`],
                other_reason: page.form.errors[`line_items[${i}].other_reason`],
                files: page.form.errors[`line_items[${i}].files`],
                description: page.form.errors[`line_items[${i}].description`],
              }}
              form={page.form}
              item={item}
              key={item.id}
              orders={page.orders}
              reasons={page.reasons}
              disableWhenFaulty
            />
          ))}
        </ul>

        <div className="mt-6 flex items-center justify-between space-x-4">
          <AppButton
            type="button"
            className="w-full px-6 py-3 text-xs uppercase tracking-widest sm:w-auto"
            onClick={() => navigate('/')}
          >
            {__('Back')}
          </AppButton>
          {!page.itemsToReturn.some((li) => matchReason(['FAULT', 'FAULTY'], li.reason)) && (
            <AppButton
              type="submit"
              className="w-full px-6 py-3 text-xs uppercase tracking-widest sm:w-auto"
              color="primary"
              disabled={page.itemsToReturn.length === 0 || page.form.processing}
            >
              {page.form.processing ? __('Processing') : pluralize(page.itemsToReturn.length, __(`Return :value item`))}
              {page.form.processing && <LoadingIcon className="ml-2 h-4 w-4 animate-spin text-white" />}
            </AppButton>
          )}
        </div>
      </form>

      <ConfirmSubmissionModal />

      <LinkModal />
    </div>
  )
}

function PageLoading() {
  return (
    <div className="space-y-10">
      <div className="space-y-2">
        <div className="h-5 w-64 animate-pulse bg-gray-200"></div>
        <div className="h-5 w-24 animate-pulse bg-gray-200"></div>
      </div>
      {times(2).map((key) => (
        <div key={key} className="flex flex-wrap items-start">
          <div className="aspect-[1/1.5] w-full animate-pulse bg-gray-200 sm:w-72"></div>
          <div className="mt-6 flex-1 sm:mt-0 sm:ml-6">
            <div className="h-5 w-2/3 animate-pulse bg-gray-200"></div>
            <div className="mt-4 h-5 w-1/3 animate-pulse bg-gray-100"></div>
            <div className="mt-4 h-5 w-1/4 animate-pulse bg-gray-100"></div>
          </div>
        </div>
      ))}
    </div>
  )
}

function PageError() {
  return (
    <ErrorPage>
      <p className="text-sm leading-relaxed text-gray-700">{__('err_order_fetch_failed')}</p>
      <Link to="/" className="mt-6 inline-block border-b border-gray-400 py-1 text-sm text-gray-700">
        {__('Return to home')}
      </Link>
    </ErrorPage>
  )
}

function ConfirmSubmissionModal() {
  const page = useContext(PageContext)

  return (
    <ConfirmModal
      confirmDisabled={page.form.processing}
      confirmLoader={true}
      confirmLabel={
        page.form.processing
          ? 'Processing' //
          : `Return ${pluralize(page.itemsToReturn.length, ':value item')}`
      }
      onClose={() => page.closeConfirmModal()}
      onConfirm={() => page.form.submit()}
      show={page.showConfirmModal}
      title="Please confirm your return details"
    >
      <p className="text-sm leading-relaxed text-gray-500">{__('returns_confirmation')}</p>

      <div className="mt-4 flex items-center">
        <h1 className="text-sm">{page.orders.map((order) => __('Order') + ' ' + order.order_no).join(' + ')}</h1>
      </div>

      <ul className="mt-6 space-y-6">
        {page.itemsToReturn.map((item) => (
          <li key={item.id} className="flex items-start">
            <img src={item.image_url} alt={item.name} className="h-8 w-8 border bg-gray-100 object-scale-down" />
            <div className="ml-4 flex-1 space-y-1 text-left">
              <h6 className="text-sm">{item.name}</h6>
              <h6 className="text-sm text-gray-500">{item.sku}</h6>
              <div className="text-sm text-gray-500">Return {pluralize(item.qty_to_return, ':value item')}</div>
              <div className="text-sm text-gray-500">{item.reason}</div>
              {item.files.length > 0 && (
                <div className="text-sm text-gray-500">{pluralize(item.files.length, ':value attachment')}</div>
              )}
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-6 space-y-2 text-left">
        <InputLabel htmlFor="phone">{__('Phone')}</InputLabel>
        <AppInput
          id="phone"
          hasError={page.form.hasError('phone')}
          onBlur={() => page.form.setData('phone', page.form.data.phone.trim())}
          onChange={(e) => page.form.setData('phone', e.target.value)}
          value={page.form.data.phone}
        />
        <InputError message={page.form.errors?.phone} />
      </div>

      <div className="mt-6 hidden text-left">
        <h6 className="text-sm text-gray-700">{__('Please choose your refund method')}</h6>
        <div className="mt-4 flex flex-col space-y-2">
          <AppRadio
            name="refund_method"
            className="space-x-2"
            value="store_credits"
            checked={page.form.data.refund_method === 'store_credits'}
            onChange={(e) => page.form.setData('refund_method', e.target.value)}
          >
            Store credit
          </AppRadio>
          <AppRadio
            name="refund_method"
            className="space-x-2"
            value="refund"
            checked={page.form.data.refund_method === 'refund'}
            onChange={(e) => page.form.setData('refund_method', e.target.value)}
          >
            Refund
          </AppRadio>
          <InputError className="mt-2" message={page.form.errors?.refund_method} />
        </div>
      </div>
    </ConfirmModal>
  )
}

function LinkModal() {
  const page = useContext(PageContext)

  const form = useForm({
    request: ReturnsService.searchOrder,

    rules: {
      email: yup.string().required(),
      orderNo: yup.string().required(),
    },

    defaults: {
      email: page.orders?.[0].email,
      isAdminInCharge: page.form.data.isAdminInCharge,
      orderNo: '',
    },

    onSuccess: (res) => {
      ReturnsService.getOrder({ hash: res.data.data })
        .then((res) => {
          page.addOrder(res.data.data)
          page.toggleLinkModal(false)
          form.reset()
        })
        .catch((err) => {
          if (err.response) {
            form.setErrors({ orderNo: __(err.response.data?.code) })
          } else {
            form.setErrors({ orderNo: __(err.code) })
          }
        })
    },

    onError: (err) => {
      form.setErrors({ orderNo: __(err.response?.data?.code?.toLowerCase()) })
    },
  })

  return (
    <ConfirmModal
      confirmDisabled={form.processing}
      confirmLabel={form.processing ? 'Searching' : 'Search order'}
      confirmLoader={true}
      onClose={() => page.toggleLinkModal(false)}
      onConfirm={form.submit}
      show={page.showLinkModal}
      title="Link a new order to this request"
    >
      <p className="text-sm text-gray-700">
        You may link another order to this return request. The line items of that order will be added to the form.
      </p>

      <form className="mt-6 space-y-4 text-left" onSubmit={form.submit}>
        <div className="space-y-2">
          <InputLabel htmlFor="email">{__('Email address')}</InputLabel>
          <AppInput hasError={form.hasError('email')} id="email" value={form.data.email} readOnly />
          <InputError message={form.errors?.email} />
        </div>

        <div className="space-y-2">
          <InputLabel htmlFor="email">{__('Order number')}</InputLabel>
          <AppInput
            hasError={form.hasError('orderNo')}
            id="order-number"
            onBlur={() => form.setData('orderNo', form.data.orderNo.trim())}
            onChange={(e) => form.setData('orderNo', e.target.value)}
            value={form.data.orderNo}
          />
          <InputError message={form.errors?.orderNo} />
        </div>
      </form>
    </ConfirmModal>
  )
}
