import { useEffect, useRef, useState } from 'react'

export default function useFlash() {
  const [data, setData] = useState(null)
  const mounted = useRef(null)

  useEffect(() => {
    if (mounted.current) return

    const data = localStorage.getItem('flash')
    localStorage.removeItem('flash')
    setData(JSON.parse(data))

    mounted.current = true
  }, [])

  return {
    data,
    remember: (value) => {
      localStorage.setItem('flash', JSON.stringify(value))
    },
  }
}
