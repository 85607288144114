import * as Sentry from '@sentry/react'
import axios from 'axios'

const http = axios.create()

http.defaults.baseURL = process.env.REACT_APP_RETURNS_API_URL

http.interceptors.response.use(null, (error) => {
  if (axios.isAxiosError(error)) {
    delete error.response.data.trace // remove stack trace when logging
    Sentry.setExtra('request', error.request.__sentry_xhr__)
    Sentry.setExtra('response', error.response.data)
    Sentry.captureException(error)
  }

  throw error
})

export default http
