import { toCountryName, toStringList } from 'utils'
import brand from './brand'

// prettier-ignore
const locales = {
  default: 'en',

  en: {
    address_au: brand.addresses.au,
    address_us: brand.addresses.us,
    alert_error_default_description: 'We are unable to process your request at the moment.',
    alert_error_default_title: 'Sorry, an error has occurred.',
    err_item_missing: 'You must include at least one item to create a return request.',
    err_max_file_size: 'Some of the files you have chosen is more than the allowed size. Please choose files with smaller file size.',
    err_order_data_mismatch: 'The return request you are trying to create does not match the order in this session.',
    err_order_fetch_failed: 'We are unable to get your order information this time.',
    err_order_from_different_store: 'Unable to add an order from a different store.',
    err_order_not_found: `The email address and order number you entered did not match our records. Please try again. If you continue to have trouble, contact ${brand.emails.info} for assistance.`,
    err_order_unavailable_for_return: 'Order is not available for return',
    err_return_date_passed: `Your order can no longer be registered for a return as it has exceeded our eligible return timeframe. If you have any concerns please contact ${brand.emails.returns}`,
    err_return_qty: 'You may only return items whose quantity is not more than the allowed quantity',
    err_session_timeout: 'Your session has expired. If you want to continue, please re-enter your order information.',
    err_shipping_gateway_failed: 'An error has occurred while retrieving the shipping label.',
    err_validation_email: 'Must be a valid email address.',
    err_validation_one_of: 'Please choose a valid option.',
    err_validation_required: 'Field is required.',
    err_validation: 'Please check your entries before submitting again.',
    page_error_default_title: 'Something went wrong',
    page_login_message: 'Please start your return by entering your details below.',
    page_summary_message: `If you are from ${toStringList(brand.countries.map(toCountryName), 'or')}, please proceed to the next step to receive a shipping label. All other countries, please follow the instructions on the return request PDF.`,
    page_summary_title: 'Return Summary',
    payment_cancel_description: `The transaction has been cancelled. Please try again. If you continue to have trouble, contact ${brand.emails.returns}`,
    payment_cancel_title: 'Payment cancelled.',
    payment_error_description: `The transaction has not gone through. Please check that your card details are correct. If you continue to have trouble, contact ${brand.emails.returns}.`,
    payment_error_title: 'Payment failed.',
    payment_success_description: 'You may now download and/or print the shipping label below.',
    payment_success_title: 'Payment successful.',
    payment_tooltip: 'Please allow up to 5 mins for your shipping label to generate after payment.',
    returns_confirmation: 'If you are sure that all the information is correct, please click the return button to finalize your request.',
    returns_created_body: 'We are already processing your request. Please check the instructions below on how to ship the items back to us.',
    returns_created_title: 'Your reference number is #:hash',
    returns_updated_body: 'Your return request have been updated successfully.',
    returns_updated_title: 'Return #:hash saved.',
    shipping_label_instructions_free: `We're sorry to hear you may have received a faulty item! Please email ${brand.emails.returns} with a description and photos of the fault. Please include a photo of the tag/barcode attached to the item as well. We look forward to resolving this!`,
    shipping_label_instructions_rest_of_the_world: 'Please download and print the return request PDF below. If you do not have access to a printer, you may simply include a handwritten note with the details of your request.',
    shipping_label_instructions: 'Box and ship your return using a pre-paid shipping label. Once we receive your return, we aim to process it within 2-3 business days.',
    shipping_label_happy_returns_text: '<p>Take the QR-Code with the return item/s to one of the Happy Returns drop off location convenient for you.</p>',
    shipping_label_happy_returns_qr_code: '<div><p class="text-center"><img style="margin: auto; padding:3% 10%"  src=":src" /></p></div>',
    shipping_label_happy_returns_button: `<p class="inline-flex items-center justify-center transition focus:outline-none disabled:opacity-50 border border-black bg-white text-black hover:bg-gray-100 relative w-full px-5 py-3 text-xs uppercase tracking-widest" ><a target="_blank" rel="noopener noreferrer" href="https://locations.happyreturns.com/?retailer=${brand.happyreturns.retailer}&distance=50&address=:address">Find Happy Returns <u><i>Locations</i></u></a></p>'`,
    shipping_label_happy_returns_warning: `Please check <a target="_blank" rel="noopener noreferrer" href="https://locations.happyreturns.com/?retailer=${brand.happyreturns.retailer}&distance=50&address=:address">Happy Returns <u><i>Locations</i></u></a> close by BEFORE you select it!`,
  },
}

export function __(str, values = {}) {
  let localized = locales[locales.default][str] || str

  Object.keys(values).map((k) => (localized = localized.replaceAll(`:${k}`, values[k])))

  return localized
}
