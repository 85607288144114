const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default function FormattedDate({ value, className }) {
  const date = new Date(value)

  if (isNaN(date.getTime())) {
    return
  }

  return (
    <time className={className} dateTime={date.toISOString()}>
      {Months[date.getMonth()]} {date.getDay()}, {date.getFullYear()}
    </time>
  )
}
