export default function TwoColumnLayout({ children }) {
  const bannerImageUrl = process.env.REACT_APP_BANNER_IMAGE_URL

  return (
    <div className="bg-cover bg-fixed" style={{ backgroundImage: `url(${bannerImageUrl})` }}>
      <div className="ml-auto h-screen overflow-auto bg-white lg:max-w-2xl">
        <div className="flex min-h-full md:items-center">{children}</div>
      </div>
    </div>
  )
}
