import { classNames } from 'utils'

export default function AppInput({ className, type = 'text', hasError = false, ...props }) {
  return (
    <input
      className={classNames(
        'form-input block w-full border-gray-300 px-3 py-2 read-only:border-dashed read-only:text-gray-500 focus:border-black focus:ring-0 sm:text-sm',
        hasError && '!border-red-500 !placeholder-red-300',
        className
      )}
      type={type}
      {...props}
    />
  )
}
