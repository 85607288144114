import http from '../http'

const ReturnsService = {
  searchOrder: (body, config) => http.post('/returns/search', body, config),
  reloadOrder: (params, config) => http.get('/returns/reloadOrder', { params, ...config }),
  getOrder: (params, config) => http.get('/returns/getOrder', { params, ...config }),
  getReasons: (config) => http.get('/returns/reasonToReturn', config),
  createReturns: (body, config) => http.post('/returns/v2/submit', body, config),
  createShipment: (body, config) => http.post('/returns/createShipment', body, config),
  getShipment: (params, config) => http.get('/returns/getShipment', { params, ...config }),
  addImagesToItem: (body, config) => http.post('/returns/addImagesToItem', body, config),
  createCheckout: (body, config) => http.post('/returns/checkout', body, config),
  editReturns: (payloads, config) =>
    Array.isArray(payloads)
      ? Promise.all(payloads.map((p) => http.post('/returns/v2/addOrRemoveReturnItems', p, config)))
      : http.post('/returns/v2/addOrRemoveReturnItems', payloads, config),
  getReturnDetails: (params, config) => http.get('/returns/v2/getReturnDetail', { params, ...config }),
  getPdf: (params) =>
    new Promise((resolve) => {
      const url = process.env.REACT_APP_RETURNS_API_URL + 'returns/getPdf'
      const query = new URLSearchParams(params).toString()
      resolve({ data: { url: `${url}?${query}` } })
    }),
}

export default ReturnsService
