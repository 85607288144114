import { useEffect } from 'react'

export function classNames(...args) {
  return args.filter(Boolean).join(' ')
}

export function useMount(callback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, [])
}

export function pluralize(value, singularForm, pluralForm = null) {
  if (value === 1) {
    return singularForm.replaceAll(':value', value)
  } else if (pluralForm === null) {
    return singularForm.replaceAll(':value', value) + 's'
  } else {
    return pluralForm.replaceAll(':value', value)
  }
}

export function toMoney(amount, currency = 'USD') {
  const money = Number.parseFloat(amount).toFixed(2)
  if (currency === 'AUD') return `AU$${money}`
  if (currency === 'USD') return `US$${money}`
  return `${currency}${money}`
}

export function toCountryName(countryCode) {
  const displayName = new Intl.DisplayNames(['en'], { type: 'region' })
  return displayName.of(countryCode)
}

export function toStringList(items, type = 'and') {
  const separator = items.length > 2 ? ', ' : ' '
  const formatter = (item, i) => (i === items.length - 1 ? `${type} ${item}` : item)
  return items.map(formatter).join(separator)
}
