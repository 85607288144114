const defaults = {
  name: 'Hello Molly',
  theme: 'hellomolly',
  emails: {
    info: 'info@hellomolly.com',
    support: 'support@hellomolly.com',
    returns: 'returns@hellomolly.com',
  },
  addresses: {
    au: 'Hello Molly<br/>8 Hiles Street<br/>Alexandria<br/>NSW 2015<br/>Australia',
    us: 'Hello Molly<br/>8738 E 9th Street<br/>Rancho Cucamonga, CA 91730<br/>United States',
  },
  happyreturns: {
    retailer: 'hellomolly',
  },
  countries: ['au', 'us', 'gb'],
}

const brand = {
  name: process.env.REACT_APP_BRAND_NAME || defaults.name,
  theme: process.env.REACT_APP_BRAND_THEME || defaults.theme,
  emails: {
    info: process.env.REACT_APP_BRAND_EMAIL_INFO || defaults.emails.info,
    support: process.env.REACT_APP_BRAND_EMAIL_SUPPORT || defaults.emails.support,
    returns: process.env.REACT_APP_BRAND_EMAIL_RETURNS || defaults.emails.returns,
  },
  addresses: {
    au: process.env.REACT_APP_BRAND_AU_ADDRESS || defaults.addresses.au,
    us: process.env.REACT_APP_BRAND_US_ADDRESS || defaults.addresses.us,
  },
  happyreturns: {
    retailer: process.env.REACT_APP_HAPPY_RETURN_RETAILER || defaults.happyreturns.retailer,
  },
  countries: process.env.REACT_APP_SUPPORTED_COUNTRIES
    ? process.env.REACT_APP_SUPPORTED_COUNTRIES.split(',')
    : defaults.countries,
}

export default brand
