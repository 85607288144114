import { useRef } from 'react'
import { classNames, useMount } from 'utils'

export default function Alert({ title, description, className, variant = 'default' }) {
  const ref = useRef()

  useMount(() => {
    ref.current.focus()
  })

  return (
    <div
      ref={ref}
      className={classNames(
        'scroll-mt-10 border-l-2 p-6 focus:outline-none',
        variant === 'danger' && 'border-rose-300 bg-rose-50',
        variant === 'success' && 'border-emerald-300 bg-emerald-50',
        variant === 'warning' && 'border-orange-300 bg-orange-50',
        className
      )}
      tabIndex={-1}
    >
      <h2
        className={classNames(
          'text-sm font-medium',
          variant === 'danger' && 'text-rose-900',
          variant === 'success' && 'text-emerald-900',
          variant === 'warning' && 'text-orange-900'
        )}
      >
        {title}
      </h2>
      <p
        className={classNames(
          'mt-1 text-sm',
          variant === 'danger' && 'text-rose-800',
          variant === 'success' && 'text-emerald-800'
        )}
      >
        {description}
      </p>
    </div>
  )
}
