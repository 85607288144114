import { Transition } from '@headlessui/react'

export default function SlideInTransition({ show, children }) {
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-100"
      enterFrom="opacity-0 -translate-y-2"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-75"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-y-2"
    >
      {children}
    </Transition>
  )
}
