import { classNames } from 'utils'

export default function AppTextarea({ className, type = 'text', hasError = false, ...props }) {
  return (
    <textarea
      className={classNames(
        'form-textarea block w-full border-gray-300 px-3 py-2 focus:border-black focus:ring-0 sm:text-sm',
        hasError && '!border-red-500 !placeholder-red-300',
        className
      )}
      type={type}
      {...props}
    ></textarea>
  )
}
