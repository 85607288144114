import { FiChevronRight } from 'react-icons/fi'
import { classNames } from 'utils'

function Breadcrumbs({ className, children }) {
  return (
    <div className={classNames('flex items-center', className)}>
      <div className="h-px grow bg-gradient-to-r from-transparent to-gray-300"></div>
      <div className="relative flex max-w-5xl items-center justify-center px-3 sm:w-full sm:justify-start">
        <div className="absolute inset-x-0 hidden h-px bg-gray-300 sm:block"></div>
        <ul className="relative inline-flex items-center space-x-1 bg-white px-3">{children}</ul>
      </div>
      <div className="h-px grow bg-gradient-to-r from-gray-300 to-transparent"></div>
    </div>
  )
}

function BreadcrumbsItem({ children }) {
  return (
    <li className="inline-flex items-center text-xs uppercase tracking-widest [&_svg]:last:hidden">
      {children}
      <FiChevronRight className="ml-1 h-4 w-4 text-gray-400" />
    </li>
  )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
