import { __ } from 'lang'
import { matchReason } from 'pages/request'
import { classNames, pluralize, toMoney } from 'utils'
import AppCheckbox from './app-checkbox'
import AppSelect from './app-select'
import AppTextarea from './app-textarea'
import Dropzone from './dropzone'
import { PackageIcon, PuzzlePieceIcon, RefundIcon, ShoppingBagIcon } from './icons'
import InputError from './input-error'
import InputLabel from './input-label'
import QuantitySelector from './quantity-selector'
import SlideInTransition from './slide-in-transition'

export default function InputLineItem({
  errors,
  form,
  item,
  orders,
  reasons,
  size = 'default',
  disableWhenFaulty = false,
}) {
  const updateLineItem = (values) => {
    const i = form.data.line_items.findIndex((_item) => _item.id === item.id)

    form.setData((prevValue) => {
      const newItems = [...prevValue.line_items]
      newItems[i] = { ...newItems[i], ...values }
      return { ...prevValue, line_items: newItems }
    })
  }

  const toggleLineItem = () => {
    updateLineItem({
      files: [],
      other_reason: '',
      should_return: !item.should_return,
      qty_to_return: !item.should_return ? 1 : 0,
      reason: '',
      description: '',
    })
  }

  return (
    <li className="flex flex-wrap items-start">
      <div className={classNames(size === 'default' && 'sm:w-72', size === 'sm' && 'sm:w-20', 'w-full')}>
        <div className="aspect-[1/1.5] overflow-hidden rounded-md ring-1 ring-black/5">
          <img
            className={classNames('h-full w-full object-cover', !item.can_return && 'opacity-50')}
            src={item.image_url}
            alt={item.name}
          />
        </div>
      </div>

      <div className="mt-6 flex-1 sm:mt-0 sm:ml-6">
        <h1 className="font-medium">{item.name}</h1>
        <h6 className="mt-1 text-sm">
          <span>{item.sku}</span>
          <span className="px-2 text-gray-300">•</span>
          <span>{toMoney(item.price_paid, orders[0].data.currency)}</span>
        </h6>
        <div className="mt-6 space-y-4">
          {orders.length > 1 && (
            <div className="flex text-sm text-gray-700">
              <ShoppingBagIcon className="mr-4 h-5 w-5 text-gray-400" />
              <span>{orders.find((o) => o.sales_order_id === item.sales_order_id)?.order_no}</span>
            </div>
          )}

          <div className="flex text-sm text-gray-700">
            <PackageIcon className="mr-4 h-5 w-5 text-gray-400" />
            <span>{pluralize(item.qty_ordered, __(':value item ordered'), __(':value items ordered'))}</span>
          </div>

          <div className="flex text-sm text-gray-700">
            <RefundIcon className="mr-4 h-5 w-5 text-gray-400" />
            <span>{pluralize(item.qty_refunded, __(':value item refunded'), __(':value items refunded'))}</span>
          </div>

          {item.piece_quantity > 1 && (
            <div className="flex text-sm text-gray-700">
              <PuzzlePieceIcon className="mr-4 h-5 w-5 text-gray-400" />
              <span>{`${item.piece_quantity}-piece set`}</span>
            </div>
          )}
        </div>

        {item.can_return ? (
          <div className="mt-4 space-y-4 overflow-hidden">
            <AppCheckbox
              className="ml-[2px] space-x-[17px] whitespace-nowrap"
              checked={item.should_return}
              onChange={toggleLineItem}
            >
              {__('Return item')}
            </AppCheckbox>

            <SlideInTransition show={item.should_return}>
              <div className="mt-6">
                <div className="flex items-start space-x-4">
                  <div className="w-24 space-y-2 sm:w-32">
                    <InputLabel>{__('Quantity')}</InputLabel>
                    <QuantitySelector
                      max={item.refundable_qty}
                      min={1}
                      onChange={(value) => updateLineItem({ qty_to_return: value })}
                      value={item.qty_to_return}
                    />
                  </div>

                  <div className="flex-1 space-y-2">
                    <InputLabel htmlFor={`reason-${item.id}`}>{__('Reason for return')}</InputLabel>
                    <AppSelect
                      hasError={Boolean(errors?.reason)}
                      id={`reason-${item.id}`}
                      onChange={(e) => updateLineItem({ reason: e.target.value, files: [], other_reason: '' })}
                      value={item.reason}
                    >
                      <option value="">{__('Select a reason')}</option>
                      {reasons.map(({ reason }) => (
                        <option key={reason}>{reason}</option>
                      ))}
                    </AppSelect>
                    <InputError message={errors?.reason} />
                  </div>
                </div>

                {disableWhenFaulty && matchReason(['FAULT', 'FAULTY'], item.reason) ? (
                  <div className="mt-6 bg-gray-100 p-4">
                    <p className="text-sm leading-relaxed text-gray-700">{__('shipping_label_instructions_free')}</p>
                  </div>
                ) : (
                  <>
                    <SlideInTransition show={String(item.reason).toUpperCase() === 'OTHER'}>
                      <div className="mt-8 space-y-2">
                        <InputLabel htmlFor={`other-reason-${item.id}`}>{__('Please specify your reason')}</InputLabel>
                        <AppTextarea
                          rows={4}
                          hasError={Boolean(errors?.other_reason)}
                          id={`other-reason-${item.id}`}
                          onChange={(e) => updateLineItem({ other_reason: e.target.value })}
                          value={item.other_reason}
                        />
                        <InputError message={errors?.other_reason} />
                      </div>
                    </SlideInTransition>

                    {item.reason?.toUpperCase() !== 'OTHER' && (
                      <div className="mt-8 space-y-2">
                        <InputLabel htmlFor={`description-${item.id}`}>
                          {matchReason(['FAULT', 'FAULTY'], item.reason)
                            ? __('Please describe the fault and attach images')
                            : __('Comments (Optional)')}
                        </InputLabel>
                        <AppTextarea
                          rows="4"
                          hasError={Boolean(errors?.description)}
                          id={`description-${item.id}`}
                          onChange={(e) => updateLineItem({ description: e.target.value })}
                          value={item.description}
                        />
                        <InputError message={errors?.description} />
                      </div>
                    )}

                    <SlideInTransition show={matchReason(['FAULT', 'FAULTY', 'WRONG ITEM RECEIVED'], item.reason)}>
                      <div className="mt-8 space-y-2">
                        <InputLabel htmlFor={`files-${item.id}`}>
                          {__('Photos of the faulty item (please include at least 1 photo showing the tag/barcode)')}
                        </InputLabel>
                        <Dropzone value={item.files} onChange={(files) => updateLineItem({ files })} />
                        <InputError message={errors?.files} />
                      </div>
                    </SlideInTransition>
                  </>
                )}
              </div>
            </SlideInTransition>
          </div>
        ) : (
          <span className="mt-4 inline-block bg-gray-200 px-3 py-2 text-sm text-gray-700">{__('Non-returnable')}</span>
        )}
      </div>
    </li>
  )
}
