import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef } from 'react'
import AppButton from './app-button'
import { LoadingIcon } from './icons'

export default function ConfirmModal({
  cancelLabel = 'Cancel',
  children,
  confirmLabel = 'Confirm',
  confirmDisabled = false,
  confirmLoader = false,
  onClose,
  onConfirm,
  show,
  title,
}) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-500 sm:mx-0 sm:h-10 sm:w-10">
                      <img
                        src="https://assets.hellomolly.com/wysiwyg/cms/FAQ-Popular-Questions.png"
                        className="h-6 w-6 object-cover"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 flex-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                </div>
                <div className="border-t px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <AppButton
                    className="w-full px-4 py-3 text-xs uppercase tracking-widest sm:w-auto"
                    color="primary"
                    disabled={confirmDisabled}
                    onClick={onConfirm}
                  >
                    <span>{confirmLabel}</span>
                    {confirmLoader && confirmDisabled && (
                      <LoadingIcon className="ml-2 h-4 w-4 animate-spin text-white" />
                    )}
                  </AppButton>
                  <AppButton
                    className="mt-3 w-full px-4 py-3 text-xs uppercase tracking-widest sm:mt-0 sm:mr-3 sm:w-auto"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    {cancelLabel}
                  </AppButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
