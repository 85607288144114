import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef } from 'react'
import { classNames } from 'utils'

export default function Modal({ children, onClose, show, title, panelClassName, actions = null }) {
  const closeButtonRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={closeButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full',
                  panelClassName || 'sm:max-w-lg'
                )}
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex items-end">
                    <Dialog.Title as="h3" className="flex-1 text-lg leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <button
                      className="inline-flex h-6 w-6 items-center justify-center rounded-full text-lg transition hover:bg-gray-100"
                      ref={closeButtonRef}
                      onClick={onClose}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="mt-2">{children}</div>
                </div>
                {actions && (
                  <div className="flex flex-col gap-y-3 border-t px-4 py-3 sm:flex-row-reverse sm:gap-y-0 sm:gap-x-3 sm:px-6">
                    {actions}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
