import { AppContext } from 'App'
import brand from 'brand'
import Alert from 'components/alert'
import AppButton from 'components/app-button'
import ErrorPage from 'components/error-page'
import FormattedDate from 'components/formatted-date'
import {
  ChatBubbleIcon,
  CreditCardIcon,
  EllipsisIcon,
  LeafIcon,
  LoadingIcon,
  PaperClipIcon,
  PrinterIcon,
  PuzzlePieceIcon,
} from 'components/icons'
import InputLineItem from 'components/input-line-item'
import Modal from 'components/modal'
import SlideInTransition from 'components/slide-in-transition'
import TwoColumnLayout from 'components/two-column-layout'
import useForm from 'hooks/use-form'
import { __ } from 'lang'
import { groupBy, times, upperFirst } from 'lodash'
import { createContext, useContext, useMemo, useState } from 'react'
import { Fragment } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import PaymentsService from 'services/payments-service'
import ReturnsService from 'services/returns-service'
import { pluralize, useMount } from 'utils'
import * as yup from 'yup'
import { LineItemModel, matchReason } from './request'

const PageContext = createContext({
  order: null,
  orderReturn: null,
})

const ReturnLineItemModel = (attributes) => ({
  ...attributes,
  images: JSON.parse(attributes.images),
})

export default function Page() {
  const [error, setError] = useState(null)
  const [order, setOrder] = useState(null)
  const [isHappyReturns, setIsHappyReturns] = useState(false)
  const [labelUrl, setLabelUrl] = useState(false)

  const app = useContext(AppContext)
  const navigate = useNavigate()

  const orderReturn = useMemo(() => {
    return order?.returns?.filter((r) => r.status.toLowerCase() === 'open')?.[0]
  }, [order])

  useMount(() => {
    ReturnsService.getOrder({ hash: app.token })
      .then((res) => handleFetch(res.data.data))
      .catch((err) => {
        if (err.response?.data?.code === 'ERR_SESSION_TIMEOUT') {
          navigate('/?status=ERR_SESSION_TIMEOUT')
        } else {
          setError(err.response)
        }
      })
  })

  function handleFetch(order) {
    if (order.isCreateEnabled) {
      navigate('/request', { replace: true })
    } else {
      setOrder(order)
    }
  }

  return (
    <PageContext.Provider value={{ order, orderReturn, isHappyReturns, setIsHappyReturns, labelUrl, setLabelUrl }}>
      <TwoColumnLayout>
        <div className="mx-auto w-full max-w-xl px-4 py-12 md:px-6">
          {order && error === null && <PageLoaded />}
          {order === null && error === null && <PageLoading />}
          {error !== null && <PageError />}
        </div>
      </TwoColumnLayout>
    </PageContext.Provider>
  )
}

function PageLoaded() {
  const [status, setStatus] = useState()
  const [expandedItems, setExpandedItems] = useState([])
  const [searchParams] = useSearchParams()

  const page = useContext(PageContext)
  const navigate = useNavigate()

  const hasFaultyItem = useMemo(
    () =>
      page.orderReturn.line_items.filter((li) =>
        ['FAULT', 'FAULTY'].includes(String(li.reason_to_return).toUpperCase()),
      ).length,
    [page.orderReturn],
  )

  const canPurchaseShippingLabel = useMemo(() => {
    const address = page.order?.data?.sales_order_addresses?.find((address) => address.is_shipping)

    if (!address) {
      return false
    }

    const country = address.country_code.toLowerCase()

    if (!brand.countries.includes(country)) {
      return false
    }

    if (country !== 'us') {
      return true
    }

    const state = address.state.toLowerCase()

    if (['hawaii', 'alaska'].includes(state)) {
      return false
    }

    return true
  }, [page.order])

  useMount(() => {
    if (searchParams.has('status')) {
      setStatus(searchParams.get('status'))
      navigate('/summary', { replace: true })
    }
  })

  function toggleAttachments(item) {
    setExpandedItems((prevValue) =>
      prevValue.includes(item.id)
        ? prevValue.filter((i) => i.id === item.id) //
        : [...prevValue, item.id],
    )
  }

  return (
    <div>
      <Link to="/" className="text-xs uppercase tracking-widest">
        &times; Exit
      </Link>

      <h1 className="mt-6 text-lg uppercase tracking-widest">{__('page_summary_title')}</h1>

      <div
        className="mt-4 text-sm leading-relaxed text-gray-500"
        dangerouslySetInnerHTML={{ __html: __('page_summary_message') }}
      />

      {status === 'payment_success' && (
        <Alert
          className="mt-10"
          title={__('payment_success_title')}
          description={__('payment_success_description')}
          variant="success"
        />
      )}

      {status === 'payment_cancel' && (
        <Alert
          className="mt-10"
          title={__('payment_cancel_title')}
          description={__('payment_cancel_description')}
          variant="warning"
        />
      )}

      {status === 'payment_error' && (
        <Alert
          className="mt-10"
          title={__('payment_error_title')}
          description={__('payment_error_description')}
          variant="danger"
        />
      )}

      <div className="mt-10 flex items-baseline justify-between">
        <div>
          <h6 className="text-sm uppercase tracking-widest text-gray-700">Reference Number</h6>
          <h2 className="mt-2 text-sm uppercase text-primary-700">{page.orderReturn.return_hash}</h2>
        </div>
        <div>
          <EditForm />
        </div>
      </div>

      <hr className="mt-6" />

      <ul>
        {page.orderReturn.line_items.map(ReturnLineItemModel).map((item) => (
          <li key={item.id} className="flex flex-wrap items-start border-b py-8">
            <div className="w-20 sm:w-24">
              <div className="aspect-[1/1.5] overflow-hidden rounded-md ring-1 ring-black/5">
                <img
                  className="h-full w-full object-cover"
                  src={item.order_line_item?.image_url}
                  alt={item.order_line_item?.name}
                />
              </div>
            </div>

            <div className="ml-6 flex-1">
              <h1 className="text-sm font-medium">{item.order_line_item?.name}</h1>
              <h6 className="text-sm text-gray-700">{item.order_line_item?.sku}</h6>

              <div className="mt-4 flex items-start">
                <ChatBubbleIcon className="mr-4 h-5 w-5 text-gray-400" />
                <span className="text-sm text-gray-700">Reason: &quot;{item.reason_to_return}&quot;</span>
              </div>

              <div className="mt-4 flex items-start">
                <EllipsisIcon className="mr-4 h-5 w-5 text-gray-400" />
                <span className="text-sm text-gray-700">
                  {pluralize(item.qty_to_return, __(':value item to return'), __(':value items to return'))}
                </span>
              </div>

              {item.order_line_item.piece_quantity > 1 && (
                <div className="mt-4 flex items-start">
                  <PuzzlePieceIcon className="mr-4 h-5 w-5 text-gray-400" />
                  <span className="text-sm text-gray-700">
                    <span>{`${item.order_line_item.piece_quantity}-piece set`}</span>
                  </span>
                </div>
              )}

              {item.images?.length > 0 && (
                <div className="mt-4 flex items-start text-gray-700">
                  <PaperClipIcon className="mr-4 h-5 w-5 text-gray-400" />
                  <button type="button" onClick={() => toggleAttachments(item)} className="text-sm underline">
                    {expandedItems.includes(item.id)
                      ? pluralize(item.images.length, __('Hide :value attachment'), __('Hide :value attachments'))
                      : pluralize(item.images.length, __('Show :value attachment'), __('Show :value attachments'))}
                  </button>
                </div>
              )}

              <SlideInTransition show={expandedItems.includes(item.id)}>
                <div className="mt-6 flex flex-wrap space-x-4">
                  {item.images?.map((image) => (
                    <a
                      className="group aspect-square w-12 overflow-hidden rounded border"
                      href={image}
                      key={image}
                      rel="referrer noopener noreferrer"
                      target="_blank"
                    >
                      <img src={image} className="h-full w-full object-scale-down transition group-hover:scale-150" />
                    </a>
                  ))}
                </div>
              </SlideInTransition>
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-8 space-y-4">
        <div className="flex items-start justify-between">
          <div className="text-sm text-gray-500">{__('Reference number')}</div>
          <div className="text-sm uppercase text-gray-700">{page.orderReturn.return_hash}</div>
        </div>
        <div className="flex items-start justify-between">
          <div className="text-sm text-gray-500">{__('Requested on')}</div>
          <FormattedDate className="text-sm text-gray-700" value={page.orderReturn.created_at} />
        </div>
        <div className="flex items-start justify-between">
          <div className="text-sm text-gray-500">{__('Order number')}</div>
          <div className="text-sm uppercase text-gray-700">
            {String(page.orderReturn.order_no).replaceAll(',', ' + ')}
          </div>
        </div>
        <div className="flex items-start justify-between">
          <div className="text-sm text-gray-500">{__('Status')}</div>
          <div className="text-sm text-gray-700">{upperFirst(page.orderReturn.status)}</div>
        </div>
        <div className="flex items-start justify-between">
          <div className="text-sm text-gray-500">{__('Refund using')}</div>
          <div className="text-sm text-gray-700">
            {page.orderReturn.is_use_store_credit && 'Store Credits'}
            {page.orderReturn.is_use_refund && 'Refund'}
          </div>
        </div>
      </div>

      <hr className="mt-8" />

      {canPurchaseShippingLabel ? (
        hasFaultyItem && !page.orderReturn.shipment_id ? (
          <div className="mt-8">
            <h6 className="text-sm uppercase tracking-widest text-gray-700">{__("What's next?")}</h6>
            <p className="mt-6 text-sm leading-relaxed text-gray-700">{__('shipping_label_instructions_free')}</p>
          </div>
        ) : (
          <div className="mt-8">
            <h6 className="text-sm uppercase tracking-widest text-gray-700">{__("What's next?")}</h6>
            <div className="mt-6">{page.orderReturn.shipment_id ? <PrintLabel /> : <Checkout />}</div>
          </div>
        )
      ) : (
        <div className="mt-8">
          <h6 className="text-sm uppercase tracking-widest text-gray-700">{__("What's next?")}</h6>
          <p className="mt-6 text-sm leading-relaxed text-gray-500">
            {__('shipping_label_instructions_rest_of_the_world')}
          </p>
          <div className="mt-6">
            <PrintForm />
          </div>
          <div className="mt-4 bg-gray-100 p-6">
            <div className="text-sm text-gray-500">Package the PDF with your return and send it to:</div>
            <div
              className="mt-4 text-sm text-gray-700"
              dangerouslySetInnerHTML={{
                __html: __(page.orderReturn.store_id === 1 ? 'address_au' : 'address_us'),
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

function PageLoading() {
  return (
    <div>
      <div className="h-5 w-64 animate-pulse bg-gray-100"></div>
      <div className="mt-2 h-5 w-40 animate-pulse bg-gray-200"></div>
      <div className="mt-10 h-5 w-2/3 animate-pulse bg-gray-100"></div>
      <div className="mt-2 h-5 w-full animate-pulse bg-gray-200"></div>
      <div className="mt-2 h-5 w-1/3 animate-pulse bg-gray-100"></div>
    </div>
  )
}

function PageError() {
  return (
    <ErrorPage>
      <p className="text-sm leading-relaxed text-gray-700">
        {__('We are unable to get your return information this time.')}
      </p>
      <Link to="/" className="mt-6 inline-block border-b border-gray-400 py-1 text-sm text-gray-700">
        {__('Return to home')}
      </Link>
    </ErrorPage>
  )
}

function PrintLabel() {
  const page = useContext(PageContext)
  const [url, setUrl] = useState(null)
  const [shipment, setShipment] = useState(null)

  const shippingCityAndState = useMemo(() => {
    const soa = page.order?.data?.sales_order_addresses?.find((address) => address.is_shipping)
    return soa && [soa.city, soa.state].filter(Boolean).join(', ')
  }, [page.order])

  const form = useForm({
    request: ReturnsService.getShipment,

    defaults: {
      hash: page.orderReturn.return_hash,
    },

    onSuccess: (res) => {
      setUrl(res.data.data.label_url)
      setShipment(res.data.data)
      page.setLabelUrl(res.data.data.label_url)
      page.setIsHappyReturns(res.data.data.status === 'QR-Code')
    },
  })

  useMount(() => {
    form.submit()
  })

  if (form.processing) {
    return (
      <div className="space-y-4">
        <div className="mt-2 h-5 w-40 animate-pulse bg-gray-200"></div>
        <div className="mt-10 h-5 w-2/3 animate-pulse bg-gray-100"></div>
        <div className="mt-2 h-5 w-full animate-pulse bg-gray-200"></div>
      </div>
    )
  }

  if (form.error) {
    return (
      <Alert
        description="An error occurred while retrieving your shipping label."
        title="Shipping label is unavailable."
        variant="danger"
        tabIndex="-1"
      />
    )
  }

  if (!page.isHappyReturns) {
    return (
      <div className="mt-6 space-y-4">
        <div
          className="space-y-6 text-sm leading-relaxed text-gray-700"
          dangerouslySetInnerHTML={{ __html: __('shipping_label_instructions') }}
        />
        <AppButton
          className="relative w-full px-5 py-3 text-xs uppercase tracking-widest"
          color="primary"
          target="_blank"
          rel="noreferrer noopener"
          href={url}
          as="a"
        >
          <PrinterIcon className="absolute left-3 h-6 w-6 text-gray-200" />
          <span>Print shipping label</span>
        </AppButton>
      </div>
    )
  }

  return (
    <div className="border p-6">
      <h1 className="text-center font-semibold">Your Happy Returns® Confirmation</h1>
      <p className="mt-6 text-sm leading-relaxed text-gray-700">
        Your return <span className="font-semibold">{shipment?.tracking_code}</span> has been started. <br />
        We&apos;ve sent a confirmation email to {page.order.email}
      </p>
      <img className="mx-auto mt-6 h-auto w-24" src={url} />
      <h2 className="mt-6 font-medium">Next Steps</h2>
      <p className="mt-2 text-sm leading-relaxed text-gray-700">
        Drop off items at any Return Bar® location. No packaging or label needed.
      </p>
      <AppButton
        as="a"
        className="relative mt-6 w-full px-5 py-3 text-xs uppercase tracking-widest"
        color="primary"
        href={`https://locations.happyreturns.com/?retailer=${brand.happyreturns.retailer}&distance=50&address=${shippingCityAndState}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        See all Return Bar® Locations
      </AppButton>
    </div>
  )
}

function PrintForm() {
  const page = useContext(PageContext)

  const form = useForm({
    request: ReturnsService.getPdf,

    defaults: {
      hash: page.orderReturn.return_hash,
    },

    onSuccess: (res) => {
      window.open(res.data.url, '_blank', 'noreferrer,noopener')
    },
  })

  return (
    <div className="space-y-4">
      <AppButton
        className="relative w-full px-5 py-3 text-xs uppercase tracking-widest"
        onClick={() => form.submit()}
        color="primary"
        type="button"
      >
        <PrinterIcon className="absolute left-3 h-6 w-6 text-gray-200" />
        <span>Print return request form</span>
      </AppButton>
    </div>
  )
}

function Checkout() {
  const page = useContext(PageContext)

  const canViewHappyReturns = useMemo(() => {
    const address = page.order?.data?.sales_order_addresses?.find((address) => address.is_shipping)
    return address && ['us'].includes(address.country_code.toLowerCase())
  }, [page.order])

  const showHappyReturn = useMemo(() => {
    if (process.env.REACT_APP_HAPPY_RETURN_LIVE_TEST == 'on') {
      return page.order?.email.search(/hellomolly\.com/i) > 0 ? true : false
    } else {
      return process.env.REACT_APP_HAPPY_RETURN_LIVE_TEST == 'off'
    }
  }, [page.order])

  const activeWidgets = useMemo(() => {
    const widgets = []

    if (canViewHappyReturns && showHappyReturn) {
      widgets.push(HappyReturnsWidget)
    }

    widgets.push(ShippingLabelWidget)

    return widgets
  }, [canViewHappyReturns, showHappyReturn])

  return (
    <div className="space-y-4">
      {activeWidgets.map((WidgetComponent, i) => (
        <Fragment key={i}>
          <WidgetComponent />
          {i < activeWidgets.length - 1 && <p className="text-center text-sm text-gray-500">or</p>}
        </Fragment>
      ))}
      <p className="bg-gray-100 p-6 text-sm text-gray-500">{__('payment_tooltip')}</p>
    </div>
  )
}

function HappyReturnsWidget() {
  const page = useContext(PageContext)

  const itemCountLimit = 9

  const disableHappyReturns = useMemo(() => {
    const itemCount = page.orderReturn.line_items.reduce((sum, li) => sum + li.qty_to_return, 0)
    return itemCount > itemCountLimit
  }, [page.orderReturn])

  const shippingCityAndState = useMemo(() => {
    const soa = page.order?.data?.sales_order_addresses?.find((address) => address.is_shipping)
    return soa && [soa.city, soa.state].filter(Boolean).join(', ')
  }, [page.order])

  const form = useForm({
    request: PaymentsService.createCheckout,

    defaults: {
      return_hash: page.orderReturn.return_hash,
      is_happy_returns: 'Yes',
    },

    onSuccess: (res) => {
      window.location.href = res.data.url
    },
  })

  return (
    <div className="border p-4 transition duration-500 hover:border-black">
      <div className="flex flex-col items-start gap-6 md:flex-row">
        <img className="h-10 w-10 object-cover md:h-14 md:w-14" src="/returns/images/happy-returns-logo.svg" />
        <div className="flex-1">
          <h6 className="text-sm text-gray-500">
            <LeafIcon className="mr-1 inline h-4 w-4 text-green-500" />
            <span>Most sustainable option</span>
          </h6>
          <h1 className="mt-1 text-lg text-gray-900">Drop items at Happy Returns®</h1>
          <p className="mt-1 text-sm text-gray-600">Packaging & label-free. Refund initiated immediately.</p>
        </div>
      </div>
      <hr className="my-4" />
      <div className="flex items-center justify-between">
        <div className="hidden text-sm text-gray-500 sm:block">Return Bar® Locations near you</div>
        <div className="text-sm text-gray-600">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://locations.happyreturns.com/?retailer=${brand.happyreturns.retailer}&distance=50&address=${shippingCityAndState}`}
            className="underline"
          >
            See all Return Bar® Locations
          </a>
        </div>
      </div>
      <AppButton
        className="relative mt-4 w-full px-5 py-3 text-xs uppercase tracking-widest"
        color="yellow"
        disabled={form.processing || disableHappyReturns}
        onClick={() => form.submit()}
        type="button"
      >
        <CreditCardIcon className="absolute left-3 h-6 w-6 text-black" />
        <span>Return via Happy Returns</span>
      </AppButton>
      {disableHappyReturns && <p className="mt-2 text-xs text-gray-500">* Must be {itemCountLimit} items or less</p>}
    </div>
  )
}

function ShippingLabelWidget() {
  const page = useContext(PageContext)

  const form = useForm({
    request: PaymentsService.createCheckout,

    defaults: {
      return_hash: page.orderReturn.return_hash,
      is_happy_returns: 'No',
    },

    onSuccess: (res) => {
      window.location.href = res.data.url
    },
  })

  return (
    <div className="border p-4 transition duration-500 hover:border-black">
      <div className="flex flex-col items-start gap-6 md:flex-row">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-700 md:h-14 md:w-14">
          <PrinterIcon className="h-6 w-6" />
        </div>
        <div className="flex-1">
          <h1>Pre-paid Shipping Label</h1>
          <p className="mt-1 text-sm leading-relaxed text-gray-700">{__('shipping_label_instructions')}</p>
        </div>
      </div>
      <AppButton
        className="relative mt-4 w-full px-5 py-3 text-xs uppercase tracking-widest"
        color="primary"
        disabled={form.processing}
        onClick={() => form.submit()}
        type="button"
      >
        <CreditCardIcon className="absolute left-3 h-6 w-6 text-gray-100" />
        <span>Purchase a shipping label</span>
      </AppButton>
    </div>
  )
}

function EditForm() {
  const page = useContext(PageContext)

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showModal, toggleModal] = useState(false)
  const [reasons, setReasons] = useState([])
  const [orders, setOrders] = useState([])
  const [editable, setEditable] = useState(true)

  const form = useForm({
    request: ReturnsService.editReturns,

    defaults: {
      line_items: [],
    },

    rules: {
      line_items: yup.array().of(
        yup.object({
          should_return: yup.boolean(),

          reason: yup.string().when('should_return', {
            is: true,
            then: () => yup.string().required(),
          }),

          other_reason: yup.string().when('reason', {
            is: (reason) => matchReason(['OTHER'], reason),
            then: () => yup.string().required(),
          }),

          description: yup.string().when('reason', {
            is: (reason) => matchReason(['FAULT', 'FAULTY'], reason),
            then: () => yup.string().required(),
          }),

          current_images: yup.array(),

          files: yup.array().when(['reason', 'current_images'], {
            is: (reason, images) =>
              matchReason(['FAULT', 'FAULTY', 'WRONG ITEM RECEIVED'], reason) && images.length === 0,
            then: () =>
              yup
                .array() //
                .min(1, 'Must upload at least one photo')
                .max(5, 'Must upload not more than five photos'),
          }),
        }),
      ),
    },

    transform: (originalData) =>
      Object.values(groupBy(originalData.line_items)).map((lineItems) => ({
        return_hash: page.orderReturn.return_hash,
        sales_order_id: lineItems[0].sales_order_id,
        line_items: lineItems.map((li) => ({
          qty_to_return: li.should_return ? li.qty_to_return : 0,
          middleware_item_id: li.middleware_item_id,
          description: li.description,
          reason_to_return:
            String(li.reason).toUpperCase() === 'OTHER' //
              ? li.other_reason
              : li.reason,
          sales_order_id: li.sales_order_id,
          sku: li.sku,
          status: 'open',
        })),
      })),

    onSuccess: async () => {
      const toUpload = form.data.line_items
        .filter((item) => item.qty_to_return > 0 && item.should_return)
        .filter(({ reason }) => matchReason(['FAULT', 'FAULTY', 'WRONG ITEM RECEIVED'], reason))
        .filter(({ files }) => files.length > 0)

      for (const item of toUpload) {
        const formData = new FormData()
        formData.append('return_hash', page.orderReturn.return_hash)
        formData.append('middleware_item_id', item.middleware_item_id)
        item.files.forEach((file, i) => formData.append(`files[${i}]`, file))
        await ReturnsService.addImagesToItem(formData)
      }

      navigate(0)
    },
  })

  useMount(() => {
    setLoading(true)

    Promise.all([
      ReturnsService.getReasons(),
      ReturnsService.getReturnDetails({ return_hash: page.orderReturn.return_hash }),
    ]).then(([_reasons, _details]) => {
      setReasons(_reasons.data.data)
      setOrders(_details.data.data.orders)

      if ('isEditable' in _details.data.data) {
        setEditable(_details.data.data.isEditable)
      }

      const newLineItems = _details.data.data.orders
        .reduce((prev, curr) => [...prev, ...Object.values(curr.line_items)], [])
        .map(LineItemModel)

      newLineItems.forEach((item) => {
        const itemToReturn = page.orderReturn.line_items.find((li) => li.middleware_item_id === item.middleware_item_id)

        if (!itemToReturn) return

        const isOtherReason = !matchReason(
          _reasons.data.data.map((r) => r.reason),
          itemToReturn.reason_to_return,
        )

        item.qty_to_return = itemToReturn.qty_to_return
        item.description = itemToReturn.description || ''
        item.reason = isOtherReason ? 'OTHER' : itemToReturn.reason_to_return
        item.other_reason = isOtherReason ? itemToReturn.reason_to_return : ''
        item.current_images = item.images ? JSON.parse(item.images) : []
        item.should_return = true
      })

      form.setData({
        line_items: newLineItems,
      })

      setLoading(false)
    })
  })

  return (
    <div>
      {editable && (
        <AppButton onClick={() => toggleModal(true)} className="px-2 py-1 text-xs uppercase tracking-widest">
          Edit
        </AppButton>
      )}
      <Modal
        onClose={() => toggleModal(false)}
        panelClassName="sm:max-w-2xl"
        show={showModal}
        title="Edit return request"
        actions={
          <>
            <AppButton
              className="w-full px-4 py-3 text-xs uppercase tracking-widest sm:w-auto"
              color="primary"
              disabled={form.processing || loading}
              onClick={() => form.submit()}
            >
              <span>{form.processing ? 'Saving' : 'Save'}</span>
              {form.processing && <LoadingIcon className="ml-2 h-4 w-4 animate-spin text-white" />}
            </AppButton>
            <AppButton
              className="w-full px-4 py-3 text-xs uppercase tracking-widest sm:w-auto"
              onClick={() => toggleModal(false)}
            >
              Cancel
            </AppButton>
          </>
        }
      >
        {form.error && (
          <Alert
            className="mt-8"
            description={__(form.error.data?.code || 'alert_error_default_description')}
            title={__('alert_error_default_title')}
            variant="danger"
            tabIndex="-1"
          />
        )}

        {!loading ? (
          <form className="mt-8 space-y-10" onSubmit={form.submit}>
            {form.data.line_items.map((item, i) => (
              <InputLineItem
                errors={{
                  reason: form.errors[`line_items[${i}].reason`],
                  other_reason: form.errors[`line_items[${i}].other_reason`],
                  files: form.errors[`line_items[${i}].files`],
                  description: form.errors[`line_items[${i}].description`],
                }}
                form={form}
                item={item}
                key={item.id}
                orders={orders}
                reasons={reasons}
                size="sm"
              />
            ))}
          </form>
        ) : (
          <div className="mt-10 space-y-10">
            <div className="space-y-2">
              <div className="h-5 w-64 animate-pulse bg-gray-200"></div>
              <div className="h-5 w-24 animate-pulse bg-gray-200"></div>
            </div>
            {times(2).map((key) => (
              <div key={key} className="flex flex-wrap items-start">
                <div className="aspect-[1/1.5] w-full animate-pulse bg-gray-200 sm:w-72"></div>
                <div className="mt-6 flex-1 sm:mt-0 sm:ml-6">
                  <div className="h-5 w-2/3 animate-pulse bg-gray-200"></div>
                  <div className="mt-4 h-5 w-1/3 animate-pulse bg-gray-100"></div>
                  <div className="mt-4 h-5 w-1/4 animate-pulse bg-gray-100"></div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </div>
  )
}
