import { useState } from 'react'

export default function useToken() {
  const [token, setToken] = useState(localStorage.getItem('token'))

  return {
    token,

    setToken: (newToken) => {
      localStorage.setItem('token', newToken)
      setToken(newToken)
    },

    removeToken: () => {
      localStorage.removeItem('token')
      setToken(null)
    },
  }
}
