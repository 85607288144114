import { AppContext } from 'App'
import { useContext } from 'react'
import { HiXMark } from 'react-icons/hi2'

export default function AdminIndicator() {
  const { isAdminInCharge } = useContext(AppContext)

  return (
    isAdminInCharge && (
      <div className="fixed inset-x-0 top-0 flex justify-center border-t-2 border-primary-700">
        <div className="flex items-center rounded-b-lg bg-primary-700 py-1 px-2 text-xs uppercase text-white">
          <span>Admin Mode</span>
          <a href="/?admin=0" className="ml-2">
            <HiXMark className="h-4 w-4" />
          </a>
        </div>
      </div>
    )
  )
}
