import { useState } from 'react'

export default function useAdmin() {
  const [isAdminInCharge, setAdminInCharge] = useState(sessionStorage.getItem('isAdminInCharge') === '1')

  return {
    isAdminInCharge,

    setAdminInCharge: (newValue) => {
      sessionStorage.setItem('isAdminInCharge', newValue)
      setAdminInCharge(newValue)
    },
  }
}
