import { __ } from 'lang'
import { useCallback, useReducer, useState } from 'react'
import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: __('err_validation_required'),
    oneOf: __('err_validation_one_of'),
  },

  string: {
    email: __('err_validation_email'),
  },
})

function reducer(state, { key, value }) {
  if (typeof key === 'string') {
    return { ...state, [key]: value }
  } else if (typeof key === 'function') {
    return key(state)
  } else {
    return { ...state, ...key }
  }
}

export default function useForm({
  request, //
  rules,
  defaults,
  transform,
  onError,
  onSuccess,
  onBeforeSend,
  onComplete,
}) {
  const [data, dispatch] = useReducer(reducer, defaults)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [wasSuccessful, setWasSuccessful] = useState(false)

  const hasError = useCallback((key) => Object.keys(errors).includes(key), [errors])
  const setErrorCode = useCallback((code) => setError({ data: { code } }), [])
  const setData = useCallback((key, value) => dispatch({ key, value }), [])

  const reset = useCallback(() => {
    setData(defaults)
    setError(null)
    setErrors({})
    setWasSuccessful(false)
  }, [defaults, setData])

  const submit = useCallback(
    async (event) => {
      event && event.preventDefault()

      setWasSuccessful(false)
      setError(null)
      setErrors({})

      try {
        rules && (await yup.object(rules).validate(data, { abortEarly: false }))
      } catch (err) {
        setErrors(err.inner.reduce((c, i) => ({ ...c, [i.path]: i.message }), {}))
        setError({ data: { code: 'err_validation' } })
        setProcessing(false)
        return
      }

      if (onBeforeSend && onBeforeSend() === false) {
        return // do not perform http request if onBeforeSend returns false
      }

      setProcessing(true)

      try {
        const res = await request(transform ? transform(data) : data)
        setWasSuccessful(true)
        onSuccess && (await onSuccess(res))
      } catch (err) {
        setError(err.response)
        onError && onError(err)
      } finally {
        setProcessing(false)
        onComplete && onComplete()
      }
    },
    [
      data, //
      onBeforeSend,
      onComplete,
      onError,
      onSuccess,
      request,
      rules,
      transform,
    ],
  )

  return {
    data,
    error,
    errors,
    hasError,
    processing,
    reset,
    setData,
    setErrorCode,
    setErrors,
    submit,
    wasSuccessful,
  }
}
